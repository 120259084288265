import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponent } from '../../../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../services/facility/facility.service';
import { IFacility } from '../../../interface/facility';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
  selector: 'app-delete-facility-dialog',
  templateUrl: './delete-facility-dialog.component.html',
  styleUrls: ['./delete-facility-dialog.component.scss']
})
export class DeleteFacilityDialogComponent implements OnInit, OnDestroy {
  facility: IFacility;

  constructor(
    private dialogRef: MatDialogRef<DeleteFacilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private facilityService: FacilityService,
    private matSnackBar: MatSnackBar,
    private loadingService: LoadingService
  ) {
    this.facility = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  isLoading: boolean;
  isLoadingSubscription: Subscription;

  ngOnInit() { }

  // Close Dialog
  closeDialog() {
    this.dialogRef.close('cancel');
  }

  //Delete facility API
  deleteFacility() {
    this.isLoading = true;
    this.facilityService.deleteFacility(this.facility.id).subscribe(res => {
      this.isLoading = false;

      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Struttura eliminata con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close('save');
    }, error => {
      this.isLoading = false;
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }

}
