import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../../services/facility/facility.service';
import { IOpeningTime } from '../../../../interface/opening-time';
import { IDuration } from '../../../../interface/duration';
import { timeout } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';

@Component({
  selector: 'app-edit-manage-slot-dialog',
  templateUrl: './edit-manage-slot-dialog.component.html',
  styleUrls: ['./edit-manage-slot-dialog.component.scss']
})
export class EditManageSlotDialogComponent implements OnInit, OnDestroy {

  isLoading: boolean;
  isLoadingSubscription: Subscription;
  localSlotProperty: string = sessionStorage.getItem('slotProperty');
  localDurations: string = sessionStorage.getItem('durations');
  localSlotDurations: string = sessionStorage.getItem('slotDurationsAfterCheck');
  slotProperty = JSON.parse(this.localSlotProperty);
  slotDurations: Array<IDuration> = JSON.parse(this.localDurations);
  slotDurationsAfterCheck: Array<IDuration> = JSON.parse(this.localSlotDurations);
  currentSlot: IDuration;
  dailyForm: FormGroup;
  dayOfWeek: string[] = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
  confSlotMap: Map<string, number> = new Map();
  confCapacityMap: Map<string, number> = new Map();

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<EditManageSlotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private facilityService: FacilityService,
    private loadingService: LoadingService
  ) {
    this.currentSlot = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.checkDailyForm();
  }

  fillConfSlotMap() {
    this.slotDurationsAfterCheck.forEach((durationAfter, index) => {
      if (durationAfter.openingTime) {
        this.confSlotMap.set(`${durationAfter.service.id}-${durationAfter.openingTime.id}`, durationAfter.durationInMinutes);
        this.confCapacityMap.set(`${durationAfter.service.id}-${durationAfter.openingTime.id}`, durationAfter.capacity);
      }
    });
  }
  // Check daily form
  checkDailyForm() {
    if (this.currentSlot) {
      this.dailyForm = this.formBuilder.group({
        dayOfWeek: [null],
        durations: this.formBuilder.array([]),
        facilityId: [this.slotProperty.idFacility],
        configurationOption: 0
      });
    }
    this.fillDurationsFormArray();
  }

  // Get durations array form.
  get durationsForm() {
    return this.dailyForm.get('durations') as FormArray;
  }

  // Method to filled durations Form Array
  fillDurationsFormArray() {
    this.slotDurations.forEach(currentDuration => {
      const splitFrom = currentDuration.openingTime.from.split(':');
      const splitTo = currentDuration.openingTime.to.split(':');
      const duration = this.formBuilder.group({
        targetDay: [this.dayOfWeek[currentDuration.openingTime.targetDay]],
        from: [`${splitFrom[0]}:${splitFrom[1]}`],
        to: [`${splitTo[0]}:${splitTo[1]}`],
        serviceId: [currentDuration.service.id],
        openingTimeId: [currentDuration.openingTime.id],
        durationInMinutes: [currentDuration.durationInMinutes, [Validators.required]],
        capacity: [currentDuration.capacity, [Validators.required]]
      });
      this.durationsForm.push(duration);
    });
  }

  // Intercept event to select day of opening time
  onChangeOpeningTime(openingTime: IOpeningTime) {
    const splitFrom = openingTime.from.split(':');
    const splitTo = openingTime.to.split(':');
    const duration = this.formBuilder.group({
      targetDay: [this.dayOfWeek[openingTime.targetDay]],
      from: [`${splitFrom[0]}:${splitFrom[1]}`],
      to: [`${splitTo[0]}:${splitTo[1]}`],
      serviceId: [this.slotProperty.idService],
      openingTimeId: [openingTime.id],
      durationInMinutes: [null, [Validators.required]]
    });
    if (this.durationsForm.length) {
      this.checkDurationIfExists(duration);
    } else {
      this.durationsForm.push(duration);
    }
  }

  // Method to control if duration item exists
  checkDurationIfExists(duration: any) {
    let find: boolean;
    this.durationsForm.controls.forEach(item => {
      if (item.get('openingTimeId').value == duration.get('openingTimeId').value) {
        find = true;
        return;
      }
    });
    if (!find) {
      this.durationsForm.push(duration);
    }
  }

  // Method to filled  duration array.
  fillDurationsList(): Array<any> {
    this.fillConfSlotMap();
    let durations = [];

    this.durationsForm.controls.forEach(duration => {
      const durationKey = `${duration.get('serviceId').value}-${duration.get('openingTimeId').value}`;
      if (this.confSlotMap.has(durationKey)) {
        this.confSlotMap.set(durationKey, Number(duration.get('durationInMinutes').value));
        this.confCapacityMap.set(durationKey, Number(duration.get('capacity').value));
      }
    });

    this.slotDurationsAfterCheck.forEach(afterDuration => {
      const durationKey = `${afterDuration.service.id}-${afterDuration.openingTime.id}`;
      if (this.confSlotMap.has(durationKey)) {
        afterDuration.durationInMinutes = this.confSlotMap.get(durationKey);
        afterDuration.capacity = this.confCapacityMap.get(durationKey);
      }
    });

    this.slotDurationsAfterCheck.forEach(durationAfter => {
      durations.push({
        serviceId: durationAfter.service.id,
        openingTimeId: durationAfter.openingTime.id,
        durationInMinutes: durationAfter.durationInMinutes,
        capacity: durationAfter.capacity
      })
    });
    return durations;
  }

  // Intercepts submit event from daily from
  onSubmitDailyForm() {
    if (!this.durationsForm.controls.length) {
      this.dailyForm.get('dayOfWeek').setErrors(Validators.required);
      return;
    }
    const dailyForm = this.dailyForm.value;
    const durations = this.fillDurationsList();

    const slotConfUpsert = {
      durations: durations,
      facilityId: dailyForm.facilityId,
      configurationOption: dailyForm.configurationOption
    };
    this.upsertSlotConfiguration(slotConfUpsert);
  }

  // Call API upsert slot configuration
  upsertSlotConfiguration(slotConfUpsert: any) {
    this.isLoading = true;
    this.facilityService.slotConfigurationUpsert(slotConfUpsert).pipe(
      timeout(600000),
    ).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Slot modificato con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close();
    }, error => {
      this.isLoading = false;
      this.dialogRef.close('error');
    })
  }

  // Close Dialog
  closeDialog() {
    this.dialogRef.close('cancel');
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
