import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { AccountService } from '../../services/account/account.service';
import { AuthService } from '../../services/auth/auth.service';
import { IUser } from '../../interface/user';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { Router } from '@angular/router';
import { SnackBarInfoComponent } from '../../components/core/snackBar/snack-bar-info/snack-bar-info.component';


export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
};

@Component({
  selector: 'app-edit-password-dialog',
  templateUrl: './edit-password-dialog.component.html',
  styleUrls: ['./edit-password-dialog.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class EditPasswordDialogComponent implements OnInit {

  profile$: Observable<FormGroup>;
  editProfileForm: FormGroup;
  submitted = false;
  userStorage = sessionStorage.getItem('user');
  localUser: IUser = JSON.parse(this.userStorage);
  isLoading: boolean;
  currentYear: number = new Date().getFullYear();
  maxDate = new Date(this.currentYear - 18, 11, 31);

  constructor(
    private dialogRef: MatDialogRef<EditPasswordDialogComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private accountService: AccountService,
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.checkEditForm();
  }

  checkEditForm() {
    this.profile$ = this.authService.getUser().pipe(map(profile =>
      this.editProfileForm = this.formBuilder.group({
        firstName: [profile.firstName],
        lastName: [profile.lastName],
        email: [profile.email, Validators.email],
        userName: [profile.userName, [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
        phoneNumber: [profile.phone, [Validators.pattern('^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\.\\/0-9]*$')]],
        birthDate: [profile.birthDate, Validators.required],
        profileImage: [null]
      })
    )).pipe(catchError(error => this.closeDialog()));
  }

  onSubmit() {
    this.submitted = true;

    if (this.editProfileForm.invalid)
      return;

    const profile: IUser = {
      id: this.localUser.id,
      firstName: this.editProfileForm.value.firstName,
      lastName: this.editProfileForm.value.lastName,
      email: this.editProfileForm.value.email,
      userName: this.editProfileForm.value.userName,
      phoneNumber: this.editProfileForm.value.phoneNumber,
      birthDate: this.editProfileForm.value.birthDate,
      profileImage: this.localUser.profileImage
    };
    this.profileUpdate(profile);
  }

  profileUpdate(profile: any) {
    this.isLoading = true;

    this.accountService.accountUpdate(profile).subscribe(res => {
      this.isLoading = false;
      this.checkUpdateProfile(profile);
    }, error => {
      console.log(error);
      this.isLoading = false;
    });
  }

  checkUpdateProfile(profile: IUser) {
    if (this.localUser.email !== this.editProfileForm.value.email) {
      this.closeDialog();
      this.router.navigate(['/welcome']);
      this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
        data: `Ci dispiace, abbiamo effettuato il logout, ti abbiamo inviato un email di conferma al nuovo indirizzo: ${this.editProfileForm.value.email}`,
        duration: 4000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "info"
      });
    } else {
      this.dialogRef.close('save');
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Profilo aggiornato con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
    }

    this.localUser.firstName = profile.firstName;
    this.localUser.lastName = profile.lastName;
    this.localUser.email = profile.email;
    this.localUser.userName = profile.userName;
    this.localUser.phoneNumber = profile.phoneNumber;
    this.localUser.birthDate = profile.birthDate;
    this.localUser.profileImage = profile.profileImage;

    sessionStorage.setItem('user', JSON.stringify(this.localUser));
  }

  closeDialog(): Observable<any> {
    this.dialogRef.close('cancel');
    return;
  }

}
