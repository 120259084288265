import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../../services/facility/facility.service';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';

@Component({
  selector: 'app-delete-service-dialog',
  templateUrl: './delete-service-dialog.component.html',
  styleUrls: ['./delete-service-dialog.component.scss']
})
export class DeleteServiceDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  serviceDelete: any;


  constructor(
    private dialogRef: MatDialogRef<DeleteServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private loadingService: LoadingService
  ) {
    this.serviceDelete = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() { }

  // Method to close dialog
  closeDialog() {
    this.dialogRef.close('cancel');
  }

  // Call API Delete Service
  deleteService() {
    this.isLoading = true;

    this.facilityService.serviceDelete(this.serviceDelete.idFacility, this.serviceDelete.service.id).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Servizio eliminato con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      const reservationResponse = {
        serviceName: this.serviceDelete.service.name,
        idFacility: this.serviceDelete.idFacility,
        reservationList: res
      };
      sessionStorage.setItem('responseL', JSON.stringify(reservationResponse));
      this.dialogRef.close(reservationResponse);
    }, error => {
      this.isLoading = false;
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }

}
