import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarErrorComponent } from '../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { LoadingService } from '../services/loading/loading.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private matSnackBar: MatSnackBar,
    private loadingService: LoadingService
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<string>> {
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        const splitUrl = this.router.url.split('/');
        const homeUrl = `/${splitUrl[1]}`;
        const errorMessage: any = error;

        if (((splitUrl[1] === 'sign-up' && error.status == 400) || (splitUrl[1] === 'sign-up' && error.status == 422))) {
          console.log(error);
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: "Email o username già in uso",
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right',
            panelClass: "error"
          });
        }

        if ((splitUrl[1] === 'welcome' && error.status == 400) || (splitUrl[1] === 'welcome' && error.status == 404) || (splitUrl[1] === 'welcome' && error.status == 422)) {
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: 'Email o Password errati',
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right',
            panelClass: "error"
          });
        }

        if (splitUrl[1] === 'welcome' && error.status == 403) {
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: 'Account disabilitato',
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right',
            panelClass: "error"
          });
        }


        if (splitUrl[1] !== 'welcome' && error.status == 403) {
          this.loadingService.sendLoagingStatus(false);
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: error.error.uiMessage,
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right',
            panelClass: "error"
          });
        }


        if (splitUrl[1] !== 'welcome' && splitUrl[1] === 'sign-up' && error.status == 404) {
          this.router.navigate([`${homeUrl}/notFound`]);
        }

        if(error.status === 400 || error.status === 404 || error.status === 409 ||  error.status === 422 || error.status === 424 ) {
          console.log(error)
          this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
            data: error.error.errors ? error.error.errors[0].uiMessage : error.error.uiMessage,
            duration: 3000,
            verticalPosition: "top",
            horizontalPosition: 'right',
            panelClass: "error"
          });
        }

        if (error.status === 500) {
          this.router.navigate([`${homeUrl}/error`]);
        }


        return throwError(errorMessage);
      })
    );
  }
}
