import { Component, OnInit} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {map, shareReplay, take} from 'rxjs/operators';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { IUser } from '../../../interface/user';
import { EditPasswordDialogComponent } from '../../../dialog/edit-password-dialog/edit-password-dialog.component';
import { ProfileDialogComponent } from '../../../dialog/profile-dialog/profile-dialog.component';
import { ChangePasswordDialogComponent } from '../../../dialog/change-password-dialog/change-password-dialog.component';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
    .pipe(
      map(result => result.matches),
      shareReplay()
    );

  account$: Observable<IUser>;
  account:IUser;
  role: string;
  date: Date = new Date();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private router: Router,
    private dialog: MatDialog,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getUser();
    setInterval(() => {
      this.date = new Date();
    }, 1000);
  }

  getUser() {
    this.account$ = this.authService.getUser().pipe(take(1));
    this.account$.subscribe(res =>{
      this.account = res;
      this.checkRoleAccount(res);
    });
  }

  //Check Account role to show sidenav corrected.
  checkRoleAccount(user: IUser) {
    user.roles.forEach((role: string) => {
      switch (role) {
        case 'Administrator':
          this.role = 'Administrator';
          break;

        case 'AffiliateUser':
          this.role = 'AffiliateUser';
          break;

        default:
          console.log('Role not found');
          break;
      }
    });
  }

  // Method that signOut and deleted cookie from browser.
  signOut() {
    sessionStorage.removeItem('accountToken');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('refreshToken');
    sessionStorage.removeItem('loginDate');
    sessionStorage.removeItem('slotDurationsAfterCheck');
    sessionStorage.removeItem('durations');
    sessionStorage.removeItem('slotProperty');
    sessionStorage.removeItem('idFacility');
    this.router.navigate(['welcome'])
  }

  // Open Edit Profile Dialog.
  openEditPasswordDialog() {
    const dialogRef = this.dialog.open(EditPasswordDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getUser(): null;
    });
  }

  // Open details profile dialog.
  openProfileDialog() {
    const dialogRef = this.dialog.open(ProfileDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });
  }

  // Open change password dialog.
  openChangePasswordDialog() {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '400px',
      disableClose: true,
      autoFocus: false
    });
  }
}
