import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarInfoComponent } from '../snackBar/snack-bar-info/snack-bar-info.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { AuthService } from '../../../services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { IUser } from '../../../interface/user';
import { IAuth } from '../../../interface/auth';
import { environment } from '../../../../environments/environment';
import { switchMap } from 'rxjs/operators';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { IUserInvitation } from '../../../interface/user-invitation';
import { Observable } from 'rxjs';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
};

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})
export class SignUpComponent implements OnInit {

  signUpForm: FormGroup;
  isLoading: boolean = false;
  currentYear: number = new Date().getFullYear();
  maxDate = new Date(this.currentYear - 18, 11, 31);
  currentEmail: string;
  facilityId: number;

  constructor(
    private formBuilder: FormBuilder,
    private enableRoute: ActivatedRoute,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private accountService: AccountService,
    private authService: AuthService
  ) {
    this.enableRoute.queryParams.subscribe(param => {
      if (param) {
        this.currentEmail = param.email;
        this.facilityId = param.facilityId;
      }
    })
  }

  ngOnInit() {
    this.checkSignupForm();
  }

  //Check ReactiveForm sign-up.
  checkSignupForm() {
    this.signUpForm = this.formBuilder.group({
      email: [this.currentEmail ? this.currentEmail : null, [Validators.required, Validators.email]],
      userName: [null, [Validators.required, Validators.pattern('^[a-zA-Z0-9]+$')]],
      password: [null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\\s])[A-Za-z\d^a-zA-Z0-9\\s].{5,}')]],
      confirmPassword: [null, Validators.required],
      firstName: [null],
      lastName: [null],
      birthDate: [null, Validators.required],
      phoneNumber: [null, [Validators.pattern('^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\.\\/0-9]*$')]],
      acceptTerms: [false, Validators.required]
    })
  }

  //Call API Auth/BusinessSignup.
  onSubmit() {
    if (this.signUpForm.invalid) {
      return;
    }

    if (this.signUpForm.value.password !== this.signUpForm.value.confirmPassword) {
      this.signUpForm.get('confirmPassword').setErrors([{ 'confirmPasswordError': true }]);
      return;
    }

    if (!this.signUpForm.value.acceptTerms) {
      this.signUpForm.get('acceptTerms').setErrors([{ 'errorAccept': true }]);
      return;
    }

    const signUpForm = this.signUpForm.value;

    if (this.currentEmail && this.facilityId) {

      const account: IUser = {
        facilityId: Number(this.facilityId),
        userName: signUpForm.userName,
        email: signUpForm.email,
        password: signUpForm.password,
        phoneNumber: signUpForm.phoneNumber,
        firstName: signUpForm.firstName,
        lastName: signUpForm.lastName,
        profileImage: '',
        birthDate: signUpForm.birthDate
      };
      this.signUpAccount(account);

    } else {
      const account: IUser = {
        userName: signUpForm.userName,
        email: signUpForm.email,
        password: signUpForm.password,
        phoneNumber: signUpForm.phoneNumber,
        firstName: signUpForm.firstName,
        lastName: signUpForm.lastName,
        profileImage: '',
        birthDate: signUpForm.birthDate
      };
      this.signUpAccount(account);
    }

   
  }

  signUpAccount(account: IUser) {
    this.isLoading = true;

    const auth: IAuth = {
      clientId: environment.clientId,
      clientSecret: environment.clientSecret,
      grantType: environment.grantTypeSignUp
    };

    if (this.currentEmail && this.facilityId) {
      this.authService.signUp(auth).pipe(
        switchMap(res => this.accountService.invitationSignUp(account, res.token))
      ).subscribe(res => {
        this.isLoading = false;
        this.router.navigate(['/welcome']);
        this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
          data: `Abbiamo inviato un email di conferma a ${this.signUpForm.value.email}`,
          duration: 3000,
          verticalPosition: "top",
          horizontalPosition: 'right',
          panelClass: "info"
        });
      }, error => {
        this.isLoading = false;
      });;
    } else {
      this.authService.signUp(auth).pipe(
        switchMap(res => this.accountService.signUp(account, res.token))
      ).subscribe(res => {
        this.isLoading = false;
        this.router.navigate(['/welcome']);
        this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
          data: `Abbiamo inviato un email di conferma a ${this.signUpForm.value.email}`,
          duration: 3000,
          verticalPosition: "top",
          horizontalPosition: 'right',
          panelClass: "info"
        });
      }, error => {
        this.isLoading = false;
      });;
    }
  }
}
