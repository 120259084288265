import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private subject  = new Subject<boolean>();

  constructor() { }

  sendLoagingStatus(isLoading: boolean) {
    this.subject.next(isLoading);
  }

  clearLoadingStatus() {
    this.subject.next();
  }

  getLoadingStatus(): Observable<boolean> {
    return this.subject.asObservable();
  }
}
