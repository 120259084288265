export const environment = {
  production: false,

  clientId: 'b0ff58f48f377e12732321ea745654372ec32013.skipline.backoffice',

  clientSecret: 'e0f0c84c-cae0-3950-d890-c79686c6a35f',

  grantTypeSignIn: 'password',

  grantTypeSignUp: 'client_credentials',

  protocol: 'https://',

  apiBaseUrl: 'apidev.skipline.smartourism.it/v1',

  rootAuth: '/Auth/',

  rootAccount: '/Account/',

  rootFacilities: '/Facilities/',

  rootCategories: '/Categories/',

  rootReservations: '/Reservations/',

  rootAgenda:'/Agenda/',

  rootPoi: '/POI/',

  rootGrants: '/Grants/'
};
