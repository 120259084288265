import {Component, OnInit, Inject} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { take, tap} from 'rxjs/operators';
import { FacilityService } from '../../../services/facility/facility.service';
import { IFacility } from '../../../interface/facility';
import {ICategory} from '../../../interface/category';
import {IService} from '../../../interface/service';
import {Router} from '@angular/router';
import { Utility } from '../../../utilities/utility';
import { IUser } from '../../../interface/user';

@Component({
  selector: 'app-details-facility-dialog',
  templateUrl: './details-facility-dialog.component.html',
  styleUrls: ['./details-facility-dialog.component.scss']
})
export class DetailsFacilityDialogComponent implements OnInit {
  isLoading: boolean;
  idFacility: number;
  facility$: Observable<IFacility>;
  servicesList: Array<IService> = [];
  categoriesList: Array<ICategory> = [];

  constructor(
    private dialogRef: MatDialogRef<DetailsFacilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private router: Router,
    private facilityService: FacilityService,
    private utility: Utility
  ) {
    this.idFacility = data;
  }

  ngOnInit() {
    this.getFacility();
  }

  getFacility() {
    this.isLoading = true;
    this.facility$ = this.facilityService.getFacilityDetails(this.idFacility).pipe(
      take(1),
      tap(facility => {
        this.isLoading = false;
        facility.categories && facility.categories.length
          ? facility.categories.map(value => this.categoriesList.push(value.name))
          : [];
        facility.services && facility.services.length
          ? facility.services.map(value => this.servicesList.push(value.name))
          : [];
      })
    );
  }

  goToSelectSection(section: string) {
    this.closeDialog();

    switch(section) {
      case 'permissions': {
        this.dialogRef.close({section: section, idFacility: this.idFacility });
        break;
      }
      case 'categories': {
        this.router.navigate(['/admin/categories']);
        break;
      }
      case 'slotConfiguration': {
        this.dialogRef.close({section: section, idFacility: this.idFacility });
        break;
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  checkPermission(permission: string, id: number): boolean {
    return this.utility.checkPermission(permission, id);
  }

}
