import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../../services/auth/auth.service';
import { AccountService } from '../../../services/account/account.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarInfoComponent } from '../snackBar/snack-bar-info/snack-bar-info.component';
import { IUser } from '../../../interface/user';
import { IAuth } from '../../../interface/auth';
import { environment } from '../../../../environments/environment';
import { switchMap, take, tap } from 'rxjs/operators';
import { Observable, iif } from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  forgotForm: FormGroup;
  forgot: boolean = false;
  user: IUser;
  isLoading: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private accountService: AccountService,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit() {
    this.getParamsFromLink();
    this.checkLoginForm();
  }

  getParamsFromLink() {
    this.activatedRoute.queryParams.pipe(
      switchMap(param => iif(() => param._userId && param._token, this.accountService.confirmEmailAccount(param._userId, param._token)))
    ).subscribe(res => res);
  }

  // Check ReactiveForm login.
  checkLoginForm() {
    this.loginForm = this.formBuilder.group({
      email: [null, Validators.required],
      password: [null, Validators.required]
    });
  }

  // Check ReactiveForm forgot password.
  checkForgotForm() {
    this.forgotForm = this.formBuilder.group({
      emailAccount: [null, [Validators.required, Validators.email]]
    });
  }

  // Call API Auth/Token.
  onSubmit() {

    if (this.loginForm.invalid) {
      return;
    }
    const loginForm = this.loginForm.value;

    const auth: IAuth = {
      clientId: environment.clientId,
      clientSecret: environment.clientSecret,
      grantType: environment.grantTypeSignIn,
      email: loginForm.email,
      password: loginForm.password
    };

    this.signIn(auth);
  }

  signIn(auth: IAuth) {
    const loginDate = new Date();
    this.isLoading = true;

    const user$: Observable<IUser> = this.authService.signIn(auth).pipe(
      tap(auth => {
        const percentage = (auth.expiresIn / 100) * 20;
        const currentExpires = auth.expiresIn - percentage;
        const newDateExpires = loginDate.setSeconds(currentExpires);
        const expires = new Date(newDateExpires).toString();
        sessionStorage.setItem('accountToken', auth.token);
        sessionStorage.setItem('refreshToken', auth.refreshToken);
        sessionStorage.setItem('loginDate', expires);
      }),
      switchMap(user => this.authService.getUser())
    );

    user$.subscribe(user => {
      take(1);
      sessionStorage.setItem('user', JSON.stringify(user));
      this.checkAccountRole(user.roles);
    }, error => this.isLoading = false);
  }

  // Check account role to navigate module correct.
  checkAccountRole(roles: Array<string>) {
    roles.forEach((role: string) => {
      switch (role) {
        case 'Administrator':
          this.isLoading = false;
          this.router.navigate(['admin']);
          break;

        case 'AffiliateUser':
          this.isLoading = false;
          this.router.navigate(['affiliate']);
          break;

        default:
          this.isLoading = false;
          console.log('Role not found');
          break;
      }
    });
  }

  // Call form forgot password.
  changeForm() {
    this.forgot = true;
    this.checkForgotForm();
  }

  // Return to sign-in form.
  goToSignIn() {
    this.forgot = false;
  }

  // Call forgot password API.
  sendEmail() {
    if (this.forgotForm.invalid) {
      return;
    }
    const email = this.forgotForm.value.emailAccount;
    this.isLoading = true;
    this.accountService.forgotPassword(email).subscribe(res => {
      this.isLoading = false;
      this.forgot = false;
      this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
        data: `Abbiamo inviato un email con il link per recuperare la password a ${email}`,
        duration: 4000,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: "info"
      });
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }
}
