import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { IFacility } from '../../../interface/facility';
import { ISlotInsert } from '../../../interface/slotInsert';
import { FacilityService } from '../../../services/facility/facility.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { ISlotConfiguration } from '../../../interface/slot-configuration';
import { IService } from '../../../interface/service';
import { Observable, Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  }
};

@Component({
  selector: 'app-add-slot-dialog',
  templateUrl: './add-slot-dialog.component.html',
  styleUrls: ['./add-slot-dialog.component.scss'],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class AddSlotDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  facility: IFacility;
  upsertSlotForm: FormGroup;
  slotConfigurations: ISlotConfiguration;
  services$: Observable<Array<IService>>;
  service: IService;
  showDateInput: boolean = false;
  showMessageConfSlot: boolean = false;
  currentDate: Date;
  maxDate: Date;
  minDate: Date;

  constructor(
    private dialogRef: MatDialogRef<AddSlotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private loadingService: LoadingService
  ) {
    this.facility = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.checkSlotConfigurationIfExists();
    this.getServiceFromFacility();
    this.checkUpsertSlotForm();
  }

  checkSlotConfigurationIfExists() {
    this.facilityService.slotConfigurationGet(this.facility.id.toString()).subscribe(res => {
      this.slotConfigurations = res;
    }, error => this.closeDialog());
  }

  getServiceFromFacility() {
    this.services$ = this.facilityService.serviceList(this.facility.id);
  }

  changeService(service: IService) {
    this.service = service;
    this.currentDate = new Date();
    if (service.latestServiceAvailableSlot) {
      this.showMessageConfSlot = false;
      this.showDateInput = true;
      const latestServiceAvailableSlotSplit = service.latestServiceAvailableSlot.split('T');
      this.currentDate = new Date(latestServiceAvailableSlotSplit[0]);
      this.currentDate.setDate(this.currentDate.getUTCDate() + 1);
      this.upsertSlotForm.get('start').setValue(this.currentDate);
      this.minDate = new Date(this.currentDate.getFullYear(), this.currentDate.getUTCMonth(), this.currentDate.getUTCDate() + 1);
      this.maxDate = new Date(this.currentDate.getFullYear(), 11, 31);
    } else {
      this.showMessageConfSlot = true;
      this.showDateInput = false;
    }
    this.upsertSlotForm.get('start').setValue(this.currentDate);
  }

  checkUpsertSlotForm() {
    this.upsertSlotForm = this.formBuilder.group({
      idFacility: [this.facility.id],
      service: [null, Validators.required],
      start: [null, Validators.required],
      end: [null, Validators.required],
    })
  }

  closeDialog() {
    this.dialogRef.close('cancel');
  }

  onSubmit() {
    if (this.upsertSlotForm.invalid) {
      return;
    }
    const upsertSlot = this.upsertSlotForm.value;

    const slotInsert: ISlotInsert = {
      serviceId: this.service.id,
      facilityId: upsertSlot.idFacility,
      from: upsertSlot.start,
      to: upsertSlot.end
    };
    this.upsertSlot(slotInsert);
  }

  upsertSlot(slotInsert: ISlotInsert) {
    this.isLoading = true;
    this.facilityService.slotUpsert(slotInsert).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Slot aggiunto con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.closeDialog();
    }, error => {
      this.isLoading = false;
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }

}
