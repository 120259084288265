import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, NgZone, OnDestroy } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../services/facility/facility.service';
import { IFacility } from '../../../interface/facility';
import { SnackBarErrorComponent } from '../../../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { Observable, Subscription } from 'rxjs';
import { ICategory } from '../../../interface/category';
import { CategoryService } from '../../../services/category/category.service';
import { debounceTime, distinctUntilChanged, map, switchMap, take } from 'rxjs/operators';
import { LoadingService } from '../../../services/loading/loading.service';


@Component({
  selector: 'app-add-facility-dialog',
  templateUrl: './add-facility-dialog.component.html',
  styleUrls: ['./add-facility-dialog.component.scss']
})
export class AddFacilityDialogComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  @ViewChild('categoryInput', { static: false }) categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  isLoading: boolean;
  isLoadingSubscription: Subscription;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  addFacilityForm: FormGroup;
  categoryCtrl = new FormControl();
  categories: Array<ICategory>;
  categoriesSelected: Array<any> = [];
  systemCategories: Array<ICategory>;
  systemCategorySelected: number;

  constructor(
    private dialogRef: MatDialogRef<AddFacilityDialogComponent>,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private categoryService: CategoryService,
    private loadingService: LoadingService
  ) {
    this.categoryCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.categoryService.getFilteredCategories(0, 500, value, this.systemCategorySelected).pipe(
        map(res => res.result)
      ))
    ).subscribe(res => {
      this.categories = res;
    });

    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.getSystemCategories();
    this.checkAddFacilityForm();
  }

  getSystemCategories() {
    this.categoryService.getCategories(0, 500, '', true).pipe(
      take(1),
      map(res => res.result)
    ).subscribe(res => {
      this.systemCategories = res;
      this.systemCategorySelected = this.systemCategories[0].id;
      this.getCategories();
    }, error => this.closeDialog());
  }

  getCategories() {
    this.categoryService.getFilteredCategories(0, 500, '', this.systemCategorySelected).pipe(
      take(1),
      map(res => res.result)
    ).subscribe(res => {
      this.categories = res;
    }, error => this.closeDialog());
  }

  ngAfterViewInit() {
    this.mapsAPILoader.load().then(() => {
      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          const lat = place.geometry.location.lat();
          const lng = place.geometry.location.lng();
          const address = `${place.address_components[1].long_name}, ${place.address_components[0].short_name}`;
          const city = `${place.address_components[2].long_name},${place.address_components[4].short_name} `;
          this.addFacilityForm.get('address').setValue(address);
          this.addFacilityForm.get('city').setValue(city);
          this.addFacilityForm.get('latitude').setValue(lat);
          this.addFacilityForm.get('longitude').setValue(lng);
        });
      });
    });
  }

  //Check reactiveForm facility form
  checkAddFacilityForm() {
    this.addFacilityForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      phone: [null, [Validators.required, Validators.pattern('^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\.\\/0-9]*$')]],
      vat: [null, [Validators.required, Validators.pattern('(IT)?[0-9]{11}')]],
      latitude: [null, [Validators.required, Validators.pattern('^(\\+|-)?(?:90(?:(?:\\.0{1,})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,})?))$')]],
      longitude: [null, [Validators.required, Validators.pattern('^(\\+|-)?(?:180(?:(?:\\.0{1,})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,})?))$')]],
      name: [null, Validators.required],
      city: [null, Validators.required],
      address: [null, Validators.required]
    });
  }

  //Method that closed dialog
  closeDialog(): Observable<any> {
    this.dialogRef.close('cancel');
    return;
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);
    if (this.categoriesSelected.length > 0)
      this.checkCategoryExist(event.option.value);
    else
      this.categoriesSelected.push(event.option.value);
  }

  checkCategoryExist(currentCategory: ICategory) {
    let find: boolean;
    this.categoriesSelected.forEach(category => {
      if (category.id == currentCategory.id) {
        find = true;
        return;
      }
    });

    if (!find)
      this.categoriesSelected.push({ id: currentCategory.id, name: currentCategory.name });
  }

  remove(category: ICategory) {
    const index = this.categoriesSelected.indexOf(category);
    if (index >= 0) {
      this.categoriesSelected.splice(index, 1);
    }
  }


  onSubmit() {
    if (this.addFacilityForm.invalid)
      return;

    const form = this.addFacilityForm.value;

    const systemCategory = this.systemCategories.filter(category => { return category.id == this.systemCategorySelected });
    const facility: IFacility = {
      id: 0,
      email: form.email,
      phone: form.phone,
      vat: form.vat,
      uniqueCode: null,
      maxPromoCount: 0,
      latitude: Number(form.latitude),
      longitude: Number(form.longitude),
      type: 'Affiliate',
      disabled: false,
      city: form.city,
      isGov: false,
      actionARadius: 0,
      tolerance: 0,
      langInfos: [{ name: form.name, address: form.address, languageCode: 'it-IT' }],
      categories: this.categoriesSelected.length ? this.categoriesSelected.concat(systemCategory) : systemCategory
    };
    this.upsertFacility(facility);
  }

  // Insert facility API
  upsertFacility(facility: IFacility) {
    this.isLoading = true;
    this.facilityService.facilityUpsert(facility).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Struttura Aggiunta con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close('save');
    }, error => {
      this.isLoading = false;
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
