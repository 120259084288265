import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ICategory } from '../../interface/category';

@Injectable({
  providedIn: 'root'
})
export class CategoryService {
  url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootCategories;
  }

  getCategories(pageNumber: number, pageSize: number, filter: string, showOnlySystem: boolean): Observable<any> {
    const body = { filter: filter, showOnlySystem: showOnlySystem, pageSize: pageSize, pageNumber: pageNumber };
    return this.httpClient.post<any>(`${this.url}Get`, body);
  }

  getFilteredCategories(pageNumber: number, pageSize: number, filter: string, parent?: number): Observable<any> {
    const body = { filter: filter, pageSize: pageSize, pageNumber: pageNumber, parent: parent };
    return this.httpClient.post<any>(`${this.url}Get`, body);
  }

  upsertCategories(category: ICategory) {
    return this.httpClient.post(`${this.url}CategoryUpsert`, category);
  }

  categoryDisable(idCategory: number) {
    const body = { id: idCategory };
    return this.httpClient.post(`${this.url}CategoryDisable`, body);
  }

  categoryEnable(idCategory: number) {
    const body = { id: idCategory };
    return this.httpClient.post(`${this.url}CategoryEnable`, body);
  }

  categoryDelete(idCategory: number) {
    return this.httpClient.delete(`${this.url}CategoryDelete?idCategory=${idCategory}`);
  }
}
