import { Component, OnInit, ViewChild } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import {FacilityService} from '../../../services/facility/facility.service';
import {IFacility} from '../../../interface/facility';
import {FormBuilder, FormGroup, Validators, FormGroupDirective} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { GrantsService } from '../../../services/grants/grants.service';
import {switchMap, map, flatMap, tap} from 'rxjs/operators';
import { IFacilityUser } from '../../../interface/facility-users';
import { IGrant } from '../../../interface/grant';
import { IUpsertGrant } from '../../../interface/upsert-grant';
import { MatSnackBar, MatDialog } from '@angular/material';
import { SnackBarSuccessComponent } from '../../core/snackBar/snack-bar-success/snack-bar-success.component';
import { SnackBarErrorComponent } from '../../core/snackBar/snack-bar-error/snack-bar-error.component';
import { DeleteUserGrantDialogComponent } from '../../../dialog/grant/delete-user-grant-dialog/delete-user-grant-dialog.component';
import { Utility } from '../../../utilities/utility';
import { Router } from '@angular/router';


@Component({
  selector: 'app-permission-management',
  templateUrl: './permission-management.component.html',
  styleUrls: ['./permission-management.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class PermissionManagementComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  columnsToDisplay: string[] = ['ruolo', 'userName', 'email', 'delete', 'action'];
  dataSource: MatTableDataSource<any>;
  expandedElement: IFacilityUser | null;
  pageIndex = 0;
  pageSize = 10;
  filterValue = '';
  isLoading: boolean;
  localFacilityId: string = sessionStorage.getItem('permissionFacility');
  idFacility: number = Number(this.localFacilityId);
  facility: IFacility;
  facilityUsers: Array<IFacilityUser>;
  totalCount: number;
  grantList: Array<IGrant>;
  addUserForm: FormGroup;

  checkedAll: boolean;

  usersPermissionList: Map<string, Map<string, boolean>> = new Map();


  constructor(
    private dialog: MatDialog,
    private router: Router,
    private formBuilder: FormBuilder,
    private facilityService: FacilityService,
    private grantService: GrantsService,
    private matSnackBar: MatSnackBar,
    public utility: Utility
  ) {
    this.facilityUsers = [];
    this.checkedAll = false;
    this.isLoading = false;
    this.dataSource = new MatTableDataSource();
  }

  ngOnInit() {
    this.getFacilityDetails();
    this.initForm();
  }

  getFacilityDetails() {
    this.isLoading = true;
    if (this.utility.checkPermission('view-grants-list', this.idFacility)) {
      this.grantService.getGrantsList().pipe(
        map(grantList => this.grantList = grantList),
        flatMap(() => this.facilityService.getFacilityDetails(this.idFacility).pipe(
          tap(resFacility => this.facility = resFacility),
          switchMap(facility => this.grantService.getFacilityUsersAndPermissions(this.pageIndex, this.pageSize, this.filterValue, facility.id).pipe(
            map(res => {
              this.totalCount = res.totalCount;
              return res.result
            }),
          ))
        ))
      ).subscribe(res => {
        this.dataSource.data = res;
        res.forEach(user => {
          const userCurrentPermission: Map<string, boolean> = new Map();
          this.grantList.forEach(grant => userCurrentPermission.set(grant.key, false));
          this.usersPermissionList.set(user.userId, userCurrentPermission);

          user.grants.forEach(grant => {
            this.usersPermissionList.get(user.userId).set(grant.key, grant.value);
          });
        });
        this.isLoading = false;
      }, error => this.isLoading = false);
    } else {
      this.facilityService.getFacilityDetails(this.idFacility).subscribe(facility => {
        this.isLoading = false;
        this.facility = facility;
      }, error => this.isLoading = false);
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pageIndex = 0;
    if (filterValue.length >= 3) {
      this.filterValue = filterValue;
      this.getFacilityDetails();
    }

    if (!filterValue.length) {
      this.filterValue = '';
      this.getFacilityDetails();
    }
  }

  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getFacilityDetails();
  }

  initForm() {
    this.addUserForm = this.formBuilder.group({
      email: [null, [Validators.email,Validators.required]]
    });
  }

  checked(status: string, userId: string) {
    if (status === 'all') {
      if (this.usersPermissionList.has(userId)) {
        this.usersPermissionList.get(userId).forEach((value, key, map) => map.set(key, true));
      }
    } else {
      if (this.usersPermissionList.has(userId)) {
        this.usersPermissionList.get(userId).forEach((value, key, map) => map.set(key, false));
      }
    }
  }

  changeUserPermission(event: any, grant: IGrant, userId: string) {
    this.usersPermissionList.get(userId).set(grant.key, event.checked)
  }

  onSubmit(formDirective: FormGroupDirective) {
    if (this.addUserForm.invalid) {
      return;
    }

    const email: string = this.addUserForm.value.email;
    this.isLoading = true;
    this.grantService.sendInvitation(this.idFacility, email).subscribe(res => {
      this.isLoading = false;
      formDirective.resetForm();
      this.addUserForm.reset();
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: `Invito inviato con successo. Abbiamo mandato un email di conferma a: ${email}`,
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
    }, error => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarErrorComponent, {
        data: `${error.error.uiMessage}`,
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "error"
      });
    });
  }

  upsertGrant(userId: string) {
    let grants: Array<IGrant> = [];
    const upsertGrant: IUpsertGrant = {
      facilityId: this.idFacility,
      usersGrants: []
    };

    this.usersPermissionList.get(userId).forEach((value, key, map) => {
      grants = [];
      map.forEach((value, key) => {
        grants.push({
          key: key,
          description: '',
          value: value
        })
      });
    });

    upsertGrant.usersGrants.push({
      userId: userId,
      grants: grants
    });

    this.isLoading = true;
    this.grantService.upsertGrant(upsertGrant).subscribe(() => {
      this.getFacilityDetails();
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Permessi aggiornati con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
    }, error => {
        this.isLoading = false;
    })
  }

  openDeleteDialog(user: IFacilityUser) {
    const dialogRef = this.dialog.open(DeleteUserGrantDialogComponent, {
      width: '500px',
      data: {
        user: user, idFacility: this.idFacility
      },
      disableClose: true,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getFacilityDetails() : null;
    });
  }

}
