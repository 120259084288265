import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordValidation } from '../../../validators/password-validator';
import { AccountService } from '../../../services/account/account.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { SnackBarInfoComponent } from '../snackBar/snack-bar-info/snack-bar-info.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../snackBar/snack-bar-success/snack-bar-success.component';
import { decode } from 'punycode';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  isLoading: boolean;
  resetPasswordForm: FormGroup;

  constructor(
    private router: Router,
    private enableRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private matSnackBar: MatSnackBar,
    private accountService: AccountService
  ) { }

  ngOnInit() {
    this.checkResetPasswordForm();
  }

  //Check ReactiveForm reset password.
  checkResetPasswordForm() {
    this.resetPasswordForm = this.formBuilder.group({
      password: [null, [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^a-zA-Z0-9\\s])[A-Za-z\d^a-zA-Z0-9\\s].{5,}')]],
      confirmPassword: [null, Validators.required]
    },
      { validator: PasswordValidation.matchPassword });
  }

  //Call API Account/reset
  resetPassword() {
    if (this.resetPasswordForm.invalid) {
      return;
    }
    let password = this.resetPasswordForm.value.password;
    let confirmPassword = this.resetPasswordForm.value.confirmPassword;

    if (password !== confirmPassword) {
      this.resetPasswordForm.get('confirmPassword').setErrors([{ 'confirmPasswordError': true }]);
    }
    this.isLoading = true;
    const resetPasswordAccount$ = this.enableRoute.queryParams.pipe(
      switchMap(param => this.accountService.accountReset(param.userId, password, confirmPassword, decodeURIComponent(param.token.replace(/ /gi, '+'))))
    );
    resetPasswordAccount$.subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: `Password resettata con successo`,
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.router.navigate(['welcome']);
    }, error => {
      this.isLoading = false;
      console.log(error);
    });
  }

}
