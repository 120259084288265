import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {HttpTokenInterceptor} from './interceptors/http-token-interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error-interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AngularMaterialModule } from './module/angular-material/angular-material.module';
import { SharedComponentsModuleModule } from './module/shared-components-module/shared-components-module.module';


import { AppComponent } from './app.component';
import { LoginComponent } from './components/core/login/login.component';
import { SignUpComponent } from './components/core/sign-up/sign-up.component';
import { ResetPasswordComponent } from './components/core/reset-password/reset-password.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SignUpComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularMaterialModule,
    AppRoutingModule,
    SharedComponentsModuleModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
