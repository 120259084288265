import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccountService } from '../../../../services/account/account.service';
import { retry, take } from 'rxjs/operators';
import * as jspf from 'jspdf'
import { FacilityService } from '../../../../services/facility/facility.service';
import { IFacility } from '../../../../interface/facility';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';

@Component({
  selector: 'app-reservation-list-dialog',
  templateUrl: './reservation-list-dialog.component.html',
  styleUrls: ['./reservation-list-dialog.component.scss']
})
export class ReservationListDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  serviceName: string;
  idFacility: number;
  facility: IFacility;
  reservationsList: Array<any> = [];
  userList: Array<any> = [];
  space = 50;

  constructor(
    private dialogRef: MatDialogRef<ReservationListDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private accountService: AccountService,
    private facilityService: FacilityService,
    private loadingService: LoadingService
  ) {
    this.serviceName = this.data.serviceName;
    this.idFacility = this.data.idFacility;
    this.reservationsList = this.data.reservationList;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.getFacility();
    this.getUserInfo();
  }

  getFacility() {
    this.facilityService.getFacilityDetails(this.idFacility).pipe(
      retry(1),
      take(1)
    ).subscribe(res => {
      this.facility = res;
    }, error => this.closeDialog())
  }

  getUserInfo() {
    this.isLoading = true;
    this.reservationsList.forEach(reservation => {
      this.userList.push({
        bookCode: reservation.bookCode,
        end: reservation.slotRef.endAt,
        start: reservation.slotRef.startAt,
        firstName: reservation.firstName,
        lastName: reservation.lastName,
        phone: reservation.phone,
        email: reservation.email
      });
    });

    this.isLoading = false;
  }

  downloadPDF() {
    var logo = new Image();
    logo.src = '../../../../../assets/image/LogoSkipline.png'

    const doc = new jspf();

    var center = (doc.internal.pageSize.getWidth() / 2) - 23;
    doc.addImage(logo, 'png', center, 5, 46, 20);
    doc.setTextColor(241, 99, 92);
    doc.setFontSize(12);
    doc.setFont('AntipastoPro');
    doc.text(20, 35, `Lista Prenotati ${this.serviceName} della struttura: ${this.facility.langInfos[0].name}`);
    doc.setFont('AntipastoPro');
    doc.setFontSize(12);
    this.userList.forEach(user => {
      const startSplit = user.start.split('T');
      const endSplit = user.end.split('T');
      const dateSplit = startSplit[0].split('-');
      const startTimeSplit = startSplit[1].split(':');
      const endTimeSplit = endSplit[1].split(':');
      const reservationDate = `${dateSplit[2]}/${dateSplit[1]}/${dateSplit[0]}`;
      const bookCode = user.bookCode;
      const from = `${startTimeSplit[0]}:${startTimeSplit[1]}`;
      const to = `${endTimeSplit[0]}:${endTimeSplit[1]}`;
      const firstName = user.firstName ? user.firstName : 'Non disponibile';
      const lastName = user.lastName ? user.lastName : 'Non disponibile';
      const email = user.email ? user.email : 'Non disponibile';
      const phone = user.phone ? user.phone : 'Non disponibile';

      if (this.space === 230) {
        doc.addPage();
        this.space = 50;
      }
      doc.setTextColor(100);
      doc.text(20, this.space, `Prenotazione del: ${reservationDate}`);
      this.space += 10;
      doc.text(20, this.space, `Codice prenotazione: ${bookCode}`);
      this.space += 10;
      doc.text(20, this.space, `Dalle: ${from}`);
      this.space += 10;
      doc.text(20, this.space, `Alle: ${to}`);
      this.space += 10;
      doc.text(20, this.space, `Nome: ${firstName}`);
      this.space += 10;
      doc.text(20, this.space, `Cognome: ${lastName}`);
      this.space += 10;
      doc.text(20, this.space, `Email: ${email}`);
      this.space += 10;
      doc.text(20, this.space, `Numero di telefono: ${phone}`);
      this.space += 10;
      if (this.space !== 220) {
        doc.line(20, this.space, 90, this.space);
      }
      this.space += 10;

    });
    const height = doc.internal.pageSize.getHeight() - 10;
    doc.setTextColor(241, 99, 92);
    doc.text("Powered by SkipLine", 100, height, 'center');
    doc.save('Lista prenotati.pdf');
    this.closeDialog();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }

}
