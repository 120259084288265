import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { IUser } from '../../interface/user';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAccount;
  }


  signUp(account: IUser, access_token: string) {
    return this.httpClient.post(this.url + 'BusinessSignup', account, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token } });
  }

  invitationSignUp(account: IUser, access_token: string) {
    return this.httpClient.post(this.url + 'InvitationBusinessSignup', account, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + access_token } });
  }



  confirmEmailAccount(_userId: string, _token: string) {
    return this.httpClient.get(this.url + `Confirm?_userId=${_userId}&_token=${_token}`);
  }

  forgotPassword(email: string) {
    const body = { 'email': email };
    return this.httpClient.post(this.url + 'Forgot', body);
  }


  accountReset(IdUser: string, password: string, confirmPassword: string, token: string) {
    const body = {
      'idUser': IdUser,
      'password': password,
      'confirmPassword': confirmPassword,
      'token': token
    };
    return this.httpClient.post(this.url + 'Reset', body, { headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'text' });
  }

  accountUpdate(profile: any) {
    return this.httpClient.post(this.url + 'update', profile);
  }

  accountChangePassword(profilePassword: any) {
    return this.httpClient.post(`${this.url}changepassword`, profilePassword);
  }

  accountUsers(pageNumber: number, pageSize: number, filter:string, role: string): Observable<any> {
    const parameters: string = `?pageNumber=${pageNumber}&pageSize=${pageSize}&filter=${filter}&role=${role}`;
    return this.httpClient.get<any>(`${this.url}UsersList${parameters}`);
  }

  accountUser(Id: string): Observable<IUser> {
    const parameters = `?Id=${Id}`;
    return this.httpClient.get<IUser>(`${this.url}UserInfo${parameters}`);
  }

  accountUpsert(user: any) {
    return this.httpClient.post(`${this.url}UpsertUser`, user);
  }

  accountDelete(Id: string) {
    const parameters = `?Id=${Id}`;
    return this.httpClient.delete(`${this.url}DeleteUser${parameters}`);
  }

  accountUserInfo(id: string):Observable<IUser> {
    return this.httpClient.get<IUser>(`${this.url}UserInfo?Id=${id}`);
  }
}
