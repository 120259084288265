import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { IFacilityUser } from '../../interface/facility-users';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { IGrant } from '../../interface/grant';
import { IUpsertGrant } from '../../interface/upsert-grant';

@Injectable({
  providedIn: 'root'
})
export class GrantsService {

  url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootGrants;
  }

  getGrantsList(): Observable<Array<IGrant>> {
    return this.httpClient.get<Array<IGrant>>(`${this.url}GetGrantsList`)
  }

  getFacilityUsersAndPermissions(pageNumber: number, pageSize: number, filter: string, facilityId: number): Observable<any> {
    const body = { "facilityId": facilityId, "filter": filter, "pageSize": pageSize, "pageNumber": pageNumber };
    return this.httpClient.post <any> (`${this.url}List`, body);
  }

  sendInvitation(facilityId: number, email: string) {
    const body = { facilityId: facilityId, email: email }
    return this.httpClient.post<any>(`${this.url}SendInvitation`, body)
  }

  upsertGrant(upsertGrant: IUpsertGrant): Observable<any> {
    return this.httpClient.post<any>(`${this.url}Upsert`, upsertGrant);
  }

  deleteGrant(facilityId: number, userId: string): Observable<any> {
    const body = { facilityId: facilityId, userId: userId }
    return this.httpClient.post<any>(`${this.url}Delete`, body);
  }


}
