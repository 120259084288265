import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HttpErrorInterceptor } from '../../interceptors/http-error-interceptor';
import { HttpTokenInterceptor } from '../../interceptors/http-token-interceptor';
import { AgmCoreModule } from '@agm/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NavbarComponent } from '../../components/core/navbar/navbar.component';
import { SnackBarSuccessComponent } from '../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { SnackBarErrorComponent } from '../../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { SnackBarInfoComponent } from '../../components/core/snackBar/snack-bar-info/snack-bar-info.component';
import { SpinnerComponent } from '../../components/shared/spinner/spinner.component';
import { ManageSlotComponent } from '../../components/affiliate/manage-slot/manage-slot.component';
import { NotFoundErrorComponent } from '../../components/shared/not-found-error/not-found-error.component';
import { InternalServerErrorComponent } from '../../components/shared/internal-server-error/internal-server-error.component';
import { EditPasswordDialogComponent } from '../../dialog/edit-password-dialog/edit-password-dialog.component';
import { ProfileDialogComponent } from '../../dialog/profile-dialog/profile-dialog.component';
import { ChangePasswordDialogComponent } from '../../dialog/change-password-dialog/change-password-dialog.component';
import { FacilityComponent } from '../../components/shared/facility/facility.component';
import { AddFacilityDialogComponent } from '../../dialog/facility/add-facility-dialog/add-facility-dialog.component';
import { EditFacilityDialogComponent } from '../../dialog/facility/edit-facility-dialog/edit-facility-dialog.component';
import { DetailsFacilityDialogComponent } from '../../dialog/facility/details-facility-dialog/details-facility-dialog.component';
import { DeleteFacilityDialogComponent } from '../../dialog/facility/delete-facility-dialog/delete-facility-dialog.component';
import { AddSlotDialogComponent } from '../../dialog/facility/add-slot-dialog/add-slot-dialog.component';
import { AddServiceDialogComponent } from '../../dialog/affiliate/service/add-service-dialog/add-service-dialog.component';
import { RenameServiceDialogComponent } from '../../dialog/affiliate/service/rename-service-dialog/rename-service-dialog.component';
import { DeleteServiceDialogComponent } from '../../dialog/affiliate/service/delete-service-dialog/delete-service-dialog.component';
import { FirstSlotCreationtDialogComponent } from '../../dialog/affiliate/manage-slot/first-slot-creationt-dialog/first-slot-creationt-dialog.component';
import { AddManageSlotDialogComponent } from '../../dialog/affiliate/manage-slot/add-manage-slot-dialog/add-manage-slot-dialog.component';
import { EditManageSlotDialogComponent } from '../../dialog/affiliate/manage-slot/edit-manage-slot-dialog/edit-manage-slot-dialog.component';
import { DeleteManageSlotDialogComponent } from '../../dialog/affiliate/manage-slot/delete-manage-slot-dialog/delete-manage-slot-dialog.component';
import { ReservationListDialogComponent } from '../../dialog/affiliate/reservation/reservation-list-dialog/reservation-list-dialog.component';
import { PermissionManagementComponent } from '../../components/admin/permission-management/permission-management.component';
import { DeleteUserGrantDialogComponent } from '../../dialog/grant/delete-user-grant-dialog/delete-user-grant-dialog.component';

@NgModule({
  declarations: [
    SnackBarSuccessComponent,
    SnackBarInfoComponent,
    SnackBarErrorComponent,
    NavbarComponent,
    SpinnerComponent,
    ManageSlotComponent,
    NotFoundErrorComponent,
    InternalServerErrorComponent,
    EditPasswordDialogComponent,
    ProfileDialogComponent,
    ChangePasswordDialogComponent,
    FacilityComponent,
    AddFacilityDialogComponent,
    EditFacilityDialogComponent,
    DeleteFacilityDialogComponent,
    DetailsFacilityDialogComponent,
    AddSlotDialogComponent,
    AddServiceDialogComponent,
    RenameServiceDialogComponent,
    DeleteServiceDialogComponent,
    FirstSlotCreationtDialogComponent,
    AddManageSlotDialogComponent,
    EditManageSlotDialogComponent,
    DeleteManageSlotDialogComponent,
    ReservationListDialogComponent,
    PermissionManagementComponent,
    DeleteUserGrantDialogComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    RouterModule,
    NgxMaterialTimepickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBhjXBIP1wOKkPjFi9yjXFog6Dn8tm3ZwY',
      libraries: ['geometry', 'places']
    })
  ],
  exports: [
    NavbarComponent,
    EditPasswordDialogComponent,
    ProfileDialogComponent,
    SpinnerComponent,
    ManageSlotComponent,
    NotFoundErrorComponent,
    InternalServerErrorComponent,
    FacilityComponent,
    AddFacilityDialogComponent,
    EditFacilityDialogComponent,
    DetailsFacilityDialogComponent,
    DeleteFacilityDialogComponent
  ],
  entryComponents: [
    SnackBarSuccessComponent,
    SnackBarInfoComponent,
    SnackBarErrorComponent,

    EditPasswordDialogComponent,
    ProfileDialogComponent,
    ChangePasswordDialogComponent,

    AddFacilityDialogComponent,
    EditFacilityDialogComponent,
    DetailsFacilityDialogComponent,
    DeleteFacilityDialogComponent,
    AddSlotDialogComponent,
    AddServiceDialogComponent,
    RenameServiceDialogComponent,
    DeleteServiceDialogComponent,
    FirstSlotCreationtDialogComponent,
    AddManageSlotDialogComponent,
    EditManageSlotDialogComponent,
    DeleteManageSlotDialogComponent,
    ReservationListDialogComponent,
    DeleteUserGrantDialogComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    }
  ],
})
export class SharedComponentsModuleModule { }
