import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-internal-server-error',
  templateUrl: './internal-server-error.component.html',
  styleUrls: ['./internal-server-error.component.scss']
})
export class InternalServerErrorComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() { }

  goToHome() {
    const splitUrl = this.router.url.split('/');
    const homeUrl = `/${splitUrl[1]}`
    this.router.navigate([homeUrl]);
  }
}
