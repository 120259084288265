import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IUser } from '../../interface/user';
import { IAuth } from '../../interface/auth';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  url: string;
  clientId: string;
  clientSecret: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootAuth;
    this.clientId = environment.clientId;
    this.clientSecret = environment.clientSecret
  }

  signUp(auth: IAuth): Observable<any> {
    return this.httpClient.post<any>(this.url + 'Token', auth);
  }

  signIn(auth: IAuth): Observable<any> {
    return this.httpClient.post<any>(this.url + 'Token', auth);
  }

  injectRefreshToken() {
    const accessToken = sessionStorage.getItem('accountToken');
    const refreshToken = sessionStorage.getItem('refreshToken');
    const body = { accessToken: accessToken, refreshToken: refreshToken };
    return this.httpClient.post(this.url + 'RefreshToken', body);
  }


  getUser(): Observable<IUser> {
    return this.httpClient.get<IUser>(this.url + 'getuser')
  }
}
