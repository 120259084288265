import { Component, OnInit, Inject, NgZone, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MapsAPILoader } from '@agm/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { SnackBarErrorComponent } from '../../../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { SnackBarSuccessComponent } from '../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../services/facility/facility.service';
import { IFacility } from '../../../interface/facility';
import { Observable, Subscription } from 'rxjs';
import { map, catchError, take, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { ICategory } from '../../../interface/category';
import { CategoryService } from '../../../services/category/category.service';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
  selector: 'app-edit-facility-dialog',
  templateUrl: './edit-facility-dialog.component.html',
  styleUrls: ['./edit-facility-dialog.component.scss']
})
export class EditFacilityDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('search', { static: false }) searchElementRef: ElementRef;
  @ViewChild('categoryInput', { static: false }) categoryInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  isLoading: boolean;
  isLoadingSubscription: Subscription;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  editFacilityForm: FormGroup;
  idFacility: number;
  facility$: Observable<FormGroup>;
  categoryCtrl = new FormControl();
  categories: Array<ICategory>;
  categoriesSelected: Array<any> = [];

  constructor(
    private dialogRef: MatDialogRef<EditFacilityDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private formBuilder: FormBuilder,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private categoryService: CategoryService,
    private loadingService: LoadingService
  ) {
    this.idFacility = data;

    this.categoryCtrl.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.categoryService.getFilteredCategories(0, 500, value).pipe(
        map(res => res.result)
      ))
    ).subscribe(res => this.categories = res);

    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.getCategories();
    this.getCategoriesAssociate();
    this.checkAddFacilityForm();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.mapsAPILoader.load().then(() => {
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            const address = `${place.address_components[1].long_name}, ${place.address_components[0].short_name}`;
            const city = `${place.address_components[2].long_name},${place.address_components[4].short_name} `;
            this.editFacilityForm.get('address').setValue(address);
            this.editFacilityForm.get('city').setValue(city);
            this.editFacilityForm.get('latitude').setValue(lat);
            this.editFacilityForm.get('longitude').setValue(lng);
          });
        });
      });
    }, 1000);

  }

  getCategories() {
    // TODO: implemented virtual scrolling.
    this.categoryService.getCategories(0, 500, '', false).pipe(
      take(1),
      map(res => res.result)
    ).subscribe(res => {
      this.categories = res;
    }, error => this.closeDialog());
  }

  getCategoriesAssociate() {
    const categories$ = this.facilityService.getFacilityDetails(this.idFacility).pipe(
      take(1),
      map(res => res.categories)
    ).pipe(catchError(error => this.closeDialog()));

    categories$.subscribe(res => {
      if (res) {
        this.categoriesSelected = res
      }
    }, error => this.closeDialog());
  }

  //Check reactiveform facility
  checkAddFacilityForm() {
    this.facility$ = this.facilityService.getFacilityDetails(this.idFacility).pipe(map(facility =>
      this.editFacilityForm = this.formBuilder.group({
        email: [facility.email, [Validators.required, Validators.email]],
        phone: [facility.phone, [Validators.required, Validators.pattern('^[+]{0,1}[(]{0,1}[0-9]{1,4}[)]{0,1}[-\\s\\.\\/0-9]*$')]],
        vat: [facility.vat, [Validators.required, Validators.pattern('(IT)?[0-9]{11}')]],
        latitude: [facility.latitude, [Validators.required, Validators.pattern('^(\\+|-)?(?:90(?:(?:\\.0{1,7})?)|(?:[0-9]|[1-8][0-9])(?:(?:\\.[0-9]{1,7})?))$')]],
        longitude: [facility.longitude, [Validators.required, Validators.pattern('^(\\+|-)?(?:180(?:(?:\\.0{1,7})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\\.[0-9]{1,7})?))$')]],
        name: [facility.langInfos[0].name, Validators.required],
        city: [facility.city, Validators.required],
        type: [facility.type],
        address: [facility.langInfos[0].address, Validators.required],
        disabled: [facility.disabled],
      })
    )).pipe(catchError(error => this.closeDialog()));

  }

  //Method that closed dialog
  closeDialog(): Observable<any> {
    this.dialogRef.close('cancel');
    return;
  }

  selected(event: MatAutocompleteSelectedEvent) {
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);
    if (this.categoriesSelected.length > 0)
      this.checkCategoryExist(event.option.value);
    else
      this.categoriesSelected.push(event.option.value);
  }

  checkCategoryExist(currentCategory: ICategory) {
    let find: boolean;
    this.categoriesSelected.forEach(category => {
      if (category.id == currentCategory.id) {
        find = true;
        return;
      }
    });

    if (!find)
      this.categoriesSelected.push({ id: currentCategory.id, name: currentCategory.name });
  }

  remove(category: ICategory) {
    const index = this.categoriesSelected.indexOf(category);
    if (index >= 0) {
      this.categoriesSelected.splice(index, 1);
    }
  }

  onSubmit() {
    if (this.editFacilityForm.invalid)
      return;

    const form = this.editFacilityForm.value;

    this.isLoading = true;

    const facility: IFacility = {
      id: this.idFacility,
      email: form.email,
      phone: form.phone,
      vat: form.vat,
      uniqueCode: null,
      maxPromoCount: 0,
      latitude: Number(form.latitude),
      longitude: Number(form.longitude),
      type: form.type,
      city: form.city,
      disabled: form.disabled,
      isGov: false,
      actionARadius: 0,
      tolerance: 0,
      langInfos: [{ name: form.name, address: form.address, languageCode: 'it-IT' }],
      categories: this.categoriesSelected.length ? this.categoriesSelected : []
    };
    this.upsertFacility(facility);
  }

  // Insert facility API
  upsertFacility(facility: IFacility) {
    this.facilityService.facilityUpsert(facility).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Struttura modificata con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close('save');
    }, error => {
      this.isLoading = false;
      this.closeDialog();
    });
  }
}
