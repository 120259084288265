import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '../../services/auth/auth.service';
import { IUser } from '../../interface/user';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile-dialog',
  templateUrl: './profile-dialog.component.html',
  styleUrls: ['./profile-dialog.component.scss']
})
export class ProfileDialogComponent implements OnInit {

    constructor(
        private authService: AuthService,
        private dialogRef: MatDialogRef<ProfileDialogComponent>
    ) {}

  isLoading: boolean;
  user$: Observable<IUser>

    ngOnInit() {
        this.getProfile();
    }

  getProfile() {
    this.user$ = this.authService.getUser();
  }

  closeDialog() {
    this.dialogRef.close();
  }

}
