import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '..//../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { IFacilityUser } from '../../../interface/facility-users';
import { GrantsService } from '../../../services/grants/grants.service';


@Component({
  selector: 'app-delete-user-grant-dialog',
  templateUrl: './delete-user-grant-dialog.component.html',
  styleUrls: ['./delete-user-grant-dialog.component.scss']
})
export class DeleteUserGrantDialogComponent implements OnInit {

  user: IFacilityUser;
  idFacility: number;

  constructor(
    private dialogRef: MatDialogRef<DeleteUserGrantDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private grantService: GrantsService,
    private matSnackBar: MatSnackBar
  ) {
    this.user = data.user;
    this.idFacility = data.idFacility;
  }
  isLoading: boolean;

  ngOnInit() { }

  closeDialog() {
    this.dialogRef.close('cancel');
  }

  deleteGrantsUser() {
    this.isLoading = true;
    this.grantService.deleteGrant(this.idFacility, this.user.userId).subscribe(res => {
      this.isLoading = false;

      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Utente eliminato con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close('save');
    }, error => {
      this.isLoading = false;
      this.closeDialog();
    });
  }

}
