import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
import { LoadingService } from '../services/loading/loading.service';


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService, private x: LoadingService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const reqUrl = req.url;
    const baseUrlAPI = `${environment.protocol}${environment.apiBaseUrl}`;

    if (!req.headers.has('Content-Type')) {
      req = req.clone({ headers: req.headers.set('Content-Type', 'application/json') });
    }

    if (reqUrl !== `${baseUrlAPI}/Auth/Token` && reqUrl !== `${baseUrlAPI}/Account/BusinessSignup` && reqUrl !== `${baseUrlAPI}/Account/InvitationBusinessSignup` && reqUrl !== `${baseUrlAPI}/Account/Forgot` && reqUrl !== `${baseUrlAPI}/Account/Reset`) {
      return next.handle(this.injectToken(req)).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return this.authService.injectRefreshToken().pipe(
              tap((res: any) => {
                const currentDate = new Date().setSeconds(res.expiresIn);
                const expires = new Date(currentDate).toString();
                sessionStorage.removeItem('accountToken');
                sessionStorage.setItem('accountToken', res.token);
                sessionStorage.removeItem('refreshToken');
                sessionStorage.setItem('refreshToken', res.refreshToken);
                sessionStorage.removeItem('loginDate');
                sessionStorage.setItem('loginDate', expires);
              }),
              switchMap(() => next.handle(this.injectToken(req)))
            );
          }
          return throwError(error);
        })
      );
    } else {
      return next.handle(req);
    }
  }

  injectToken(req: HttpRequest<any>) {
    const accessToken: string = sessionStorage.getItem('accountToken');
    if (accessToken) {
      return req.clone({
        headers: req.headers.set('Authorization', `Bearer ${accessToken}`)
      });
    }
  }

}
