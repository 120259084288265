import { Injectable } from '@angular/core'
import { IUser } from '../interface/user';

@Injectable({
  providedIn: 'root'
})

export class Utility {

  checkPermission(permission: string, id: number): boolean {
    const localUser: string = sessionStorage.getItem('user');
    const user: IUser = JSON.parse(localUser);
    let claimsFilter: Array<any> = [];

    if ( user && user.roles[0] === 'AffiliateUser') {
      if (permission === 'upsert-facility') {
        claimsFilter = user.claims.filter(claim => claim.key === permission && claim.value);
      } else {
        claimsFilter = user.claims.filter(claim => claim.key === permission && claim.value.includes(id));
      }

      return claimsFilter.length > 0;
    } else {
      return true;
    }
  }
}
