import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarInfoComponent } from '../../core/snackBar/snack-bar-info/snack-bar-info.component';
import { FacilityService } from '../../../services/facility/facility.service';
import { IFacility } from '../../../interface/facility';
import { ICategory } from '../../../interface/category';
import { IUser } from '../../../interface/user';
import { map, retry } from 'rxjs/operators';
import { AddFacilityDialogComponent } from '../../../dialog/facility/add-facility-dialog/add-facility-dialog.component';
import { EditFacilityDialogComponent } from '../../../dialog/facility/edit-facility-dialog/edit-facility-dialog.component';
import { DeleteFacilityDialogComponent } from '../../../dialog/facility/delete-facility-dialog/delete-facility-dialog.component';
import { AddSlotDialogComponent } from '../../../dialog/facility/add-slot-dialog/add-slot-dialog.component';
import { DetailsFacilityDialogComponent } from '../../../dialog/facility/details-facility-dialog/details-facility-dialog.component';
import { CategoryService } from 'src/app/services/category/category.service';
import { Utility } from '../../../utilities/utility';
import { Subscription, Observable } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss']
})
export class FacilityComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  localUser: string = sessionStorage.getItem('user');
  user: IUser = JSON.parse(this.localUser);
  displayedColumnsAffiliate: string[] = ['name', 'address', 'userAssociated', 'disabled', 'addSlot', 'permissions', 'details', 'edit', 'delete'];
  displayedColumnsAdministrator: string[] = ['name', 'address', 'userAssociated', 'disabled', 'manageSlot', 'addSlot', 'permissions', 'details', 'edit', 'delete'];
  dataSource: MatTableDataSource<any>;

  isLoadingSubscription: Subscription;
  x: Observable<boolean>;
  isLoading: boolean;

  facilities: IFacility[] = [];
  pageIndex: number = 0;
  pageSize: number = 10;
  filterValue = { filter: "", categories: undefined };
  categories = [];
  serviceCategorySelected: number;

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private categoryService: CategoryService,
    private utility: Utility,
    private loadingService: LoadingService
  ) {
    this.dataSource = new MatTableDataSource();
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.getFacilities();
    this.getCategories();
  }

  checkPermission(permission: string, id: number): boolean {
    return this.utility.checkPermission(permission, id);
  }

  // Call Get Facilities API
  getFacilities() {
    this.isLoading = true;
    const facilities$ = this.facilityService.getFacilities(this.pageIndex, this.pageSize, this.filterValue.filter, this.filterValue.categories).pipe(
      retry(1)
    );
    facilities$.subscribe(res => {
      this.isLoading = false;
      this.facilities = res;
    }, error => {
      this.isLoading = false;
    });
  }

  getCategories() {
    this.isLoading = true;
    const categories$ = this.categoryService.getCategories(0, 500, "", false);

    categories$.subscribe(res => {
      const c: ICategory[] = res.result;
      this.categories["system"] = c.filter(category => category.isSystem);

      this.categories["system"].forEach(category => {
        this.categories[category.id] = c.filter(cat => cat.parent == category.id);
      });

      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.pageIndex = 0;
    if (filterValue.length >= 3) {
      this.filterValue.filter = filterValue;
      this.getFacilities();
    }

    if (!filterValue.length) {
      this.filterValue.filter = '';
      this.getFacilities();
    }
  }

  applyCategoryFilter(value) {
    if (Array.isArray(value)) {
      this.filterValue.categories = [this.serviceCategorySelected].concat(value);
    } else {
      this.serviceCategorySelected = value;
      this.filterValue.categories = value ? [value] : undefined;
    }
    this.getFacilities();
  }

  // Method to control pagination Table.
  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
    this.getFacilities();
  }

  //Call API upsert Facility.
  disabledOrEnabledFacility(facility: IFacility) {
    this.isLoading = true;

    facility.disabled = !facility.disabled;
    const facilityUpdate$ = this.facilityService.facilityUpsert(facility).pipe(map(() => this.getFacilities()));
    facilityUpdate$.subscribe(response => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarInfoComponent, {
        data: facility.disabled ? 'Struttura disabilitata' : 'Struttura abilitata',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "info"
      });
      this.getFacilities();
    }, error => {
      this.isLoading = false;
      this.getFacilities();
    });
  }

  //Open add dialog facility
  openAddFacilityDialog() {
    const dialogRef = this.dialog.open(AddFacilityDialogComponent, {
      width: '600px',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getFacilities() : null;
    });
  }

  // Open add slot dialog
  openAddSlotDialog(facility: IFacility) {
    const dialogRef = this.dialog.open(AddSlotDialogComponent, {
      width: '600px',
      data: facility,
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getFacilities() : null;
    });
  }

  // Go to slot configuration
  goToSlotConfiguration(idFacility: number) {
    sessionStorage.setItem('confSlotFacility', idFacility.toString());
    this.router.navigate(['/admin/slotConfiguration'])
  }

  goToPermissionManagement(idFacility: number) {
    sessionStorage.setItem('permissionFacility', idFacility.toString());
    this.router.navigate([this.router.url.includes('admin') ? '/admin/permissionManagement' : '/affiliate/permissionManagement'])
  }

  openDetails(idFacility: number) {
    const dialogRef = this.dialog.open(DetailsFacilityDialogComponent, {
      width: '600px',
      data: idFacility,
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.section === 'slotConfiguration' ? this.goToSlotConfiguration(result.idFacility) : this.goToPermissionManagement(result.idFacility);
      }
    });
  }

  //Open edit dialog facility
  openEditDialog(idFacility: number) {
    const dialogRef = this.dialog.open(EditFacilityDialogComponent, {
      width: '600px',
      data: idFacility,
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getFacilities() : null;
    });
  }

  //Open delete dialog facility
  openDeleteDialog(facility: IFacility) {
    const dialogRef = this.dialog.open(DeleteFacilityDialogComponent, {
      width: '450px',
      data: facility,
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        this.getFacilities();
        if (facility.id == Number(sessionStorage.getItem('idFacility'))) sessionStorage.setItem('idFacility', undefined);
      }
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
