import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IDuration } from '../../../../interface/duration';
import { timeout } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../../services/facility/facility.service';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';


@Component({
  selector: 'app-delete-manage-slot-dialog',
  templateUrl: './delete-manage-slot-dialog.component.html',
  styleUrls: ['./delete-manage-slot-dialog.component.scss']
})
export class DeleteManageSlotDialogComponent implements OnInit {
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  slotConf: IDuration;
  localSlotProperty: string = sessionStorage.getItem('slotProperty');
  slotProperty = JSON.parse(this.localSlotProperty);
  localSlotDurations: string = sessionStorage.getItem('slotDurationsAfterCheck');
  slotDurationsAfterCheck: Array<IDuration> = JSON.parse(this.localSlotDurations);
  dayOfWeek: string[] = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

  constructor(
    private dialogRef: MatDialogRef<DeleteManageSlotDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private loadingService: LoadingService
  ) {
    this.slotConf = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() { }

  closeDialog() {
    this.dialogRef.close('cancel');
  }

  deleteDuration() {
    this.slotDurationsAfterCheck.forEach((durationAfter, index) => {
      if (this.slotConf.service.id === durationAfter.service.id && this.slotConf.openingTime.id === durationAfter.openingTime.id) {
        this.slotDurationsAfterCheck.splice(index, 1);
      }
    });
    const slotConfigurationUpsert = {
      durations: [],
      facilityId: this.slotProperty.idFacility,
      configurationOption: 0
    };
    this.slotDurationsAfterCheck.forEach(duration => {
      slotConfigurationUpsert.durations.push({
        serviceId: duration.service.id,
        openingTimeId: duration.openingTime.id,
        durationInMinutes: duration.durationInMinutes
      });
    });
    this.upsertSlotConfiguration(slotConfigurationUpsert);
  }

  // Call API upsert slot configuration
  upsertSlotConfiguration(slotConfUpsert: any) {
    this.isLoading = true;
    this.facilityService.slotConfigurationUpsert(slotConfUpsert).pipe(
      timeout(600000),
    ).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Slot eliminato con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close('delete');
    }, error => {
      this.isLoading = false;
      this.dialogRef.close('error');
    })
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }

}
