import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { IFacility } from '../../interface/facility';
import { IService } from '../../interface/service';
import { IOpeningTime } from '../../interface/opening-time';
import { ISlotConfiguration } from '../../interface/slot-configuration';
import { ISlotInsert } from '../../interface/slotInsert';

@Injectable({
  providedIn: 'root'
})
export class FacilityService {
  url: string;

  constructor(private httpClient: HttpClient) {
    this.url = environment.protocol + environment.apiBaseUrl + environment.rootFacilities;
  }

  facilityUpsert(facility: IFacility) {
    return this.httpClient.post(`${this.url}FacilityUpsert`, facility);
  }

  getFacilities(pageNumber: number, pageSize: number, filter: string, categories?: Array<number>): Observable<any> {
    const body = { "filter": filter, "pageSize": pageSize, "pageNumber": pageNumber, "categories": categories };
    return this.httpClient.post<any>(`${this.url}Get`, body);
  }

  getFilteredFacilities(pageNumber: number, pageSize: number, filter: string): Observable<any> {
    const body = { "filter": filter, "pageSize": pageSize, "pageNumber": pageNumber };
    return this.httpClient.post<any>(`${this.url}Get`, body);
  }

  getFacilityDetails(idFacility: number): Observable<IFacility> {
    return this.httpClient.get<IFacility>(`${this.url}FacilityDetails?idFacility=${idFacility}`);
  }

  deleteFacility(idFacility: number) {
    return this.httpClient.delete(`${this.url}FacilityDelete?idFacility=${idFacility}`);
  }

  openingTimeList(FacilityId: number, PageStart: number, Offset: number): Observable<any> {
    const parameters: string = `?FacilityId=${FacilityId}&PageStart=${PageStart}&Offset=${Offset}`;
    return this.httpClient.get(`${this.url}OpeningTimeList${parameters}`);
  }

  openingTimeUpsert(openingTime: IOpeningTime) {
    return this.httpClient.post(`${this.url}OpeningTimeUpsert`, openingTime);
  }

  openingTimeDelete(openingTime: any) {
    return this.httpClient.post(`${this.url}OpeningTimeDelete`, openingTime);
  }


  serviceList(FacilityId: number): Observable<IService[]> {
    return this.httpClient.get<IService[]>(`${this.url}ServicesList?FacilityId=${FacilityId}`)
  }

  serviceUpsert(serviceUpsert: any) {
    return this.httpClient.post(`${this.url}ServicesUpsert`, serviceUpsert);
  }

  serviceDelete(FacilityId: number, ServiceId: number) {
    const parameters = `?FacilityId=${FacilityId}&ServiceId=${ServiceId}`;
    return this.httpClient.delete(`${this.url}ServicesDelete${parameters}`);
  }

  slotUpsert(slotInsert: ISlotInsert) {
    return this.httpClient.post(`${this.url}SlotUpsert`, slotInsert);
  }

  slotConfigurationGet(FacilityId: string): Observable<ISlotConfiguration> {
    return this.httpClient.get<ISlotConfiguration>(`${this.url}SlotConfigurationGet?FacilityId=${FacilityId}`);
  }

  slotConfigurationUpsert(slotConfigurationUpsert: any) {
    return this.httpClient.post(`${this.url}SlotConfigurationUpsert`, slotConfigurationUpsert);
  }
}
