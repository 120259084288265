import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../../services/facility/facility.service';
import { IService } from '../../../../interface/service';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../../services/loading/loading.service';


@Component({
  selector: 'app-add-service-dialog',
  templateUrl: './add-service-dialog.component.html',
  styleUrls: ['./add-service-dialog.component.scss']
})
export class AddServiceDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  upsertForm: FormGroup;
  facilityId: number;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<AddServiceDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private loadingService: LoadingService
  ) {
    this.facilityId = data;
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.checkUpsertForm();
  }

  // check upsert form
  checkUpsertForm() {
    this.upsertForm = this.formBuilder.group({
      facilityId: [this.facilityId],
      name: [null, Validators.required],
      slotDurationInMinutes: [0, Validators.required]
    });
  }

  // Method to close dialog
  closeDialog() {
    this.dialogRef.close('cancel');
  }

  // Intercept submit event from upsertFrom
  onSubmit() {
    if (this.upsertForm.invalid) {
      return;
    }
    const upsertForm = this.upsertForm.value;

    const serviceUpsert: any = {
      facilityId: upsertForm.facilityId,
      services: [{
        slotDurationInMinutes: upsertForm.slotDurationInMinutes,
        id: 0,
        name: upsertForm.name
      }]
    };
    this.upsertService(serviceUpsert);
  }

  upsertService(serviceUpsert: any) {
    this.isLoading = true;
    this.facilityService.serviceUpsert(serviceUpsert).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Servizio aggiunto con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close('save');
    }, error => {
      this.isLoading = false;
      this.closeDialog();
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
