import {Component, OnInit, ViewChild, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FacilityService } from '../../../services/facility/facility.service';
import {ISlotConfiguration} from '../../../interface/slot-configuration';
import {IDuration} from '../../../interface/duration';
import {IFacility} from '../../../interface/facility';
import {retry, take} from 'rxjs/operators';
import {AddManageSlotDialogComponent} from '../../../dialog/affiliate/manage-slot/add-manage-slot-dialog/add-manage-slot-dialog.component';
import {EditManageSlotDialogComponent} from '../../../dialog/affiliate/manage-slot/edit-manage-slot-dialog/edit-manage-slot-dialog.component';
import {DeleteManageSlotDialogComponent} from '../../../dialog/affiliate/manage-slot/delete-manage-slot-dialog/delete-manage-slot-dialog.component';
import { FirstSlotCreationtDialogComponent } from '../../../dialog/affiliate/manage-slot/first-slot-creationt-dialog/first-slot-creationt-dialog.component';
import { Utility } from '../../../utilities/utility';
import { Subscription } from 'rxjs';
import { LoadingService } from '../../../services/loading/loading.service';


@Component({
  selector: 'app-manage-slot',
  templateUrl: './manage-slot.component.html',
  styleUrls: ['./manage-slot.component.scss']
})
export class ManageSlotComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  currentAccount:string;
  displayedColumnsDaily: string[] = ['day', 'slot', 'edit', 'delete'];
  displayedColumnsWeekly: string[] = ['slot', 'edit'];
  dataSource: MatTableDataSource<any>;
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  localSlotProperty: string = sessionStorage.getItem('slotProperty');
  slotProperty = JSON.parse(this.localSlotProperty);
  facility: IFacility;
  slotConfigurations: ISlotConfiguration;
  durations: Array<IDuration> = [] ;
  dayOfWeek: string[] = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private matSnackBar: MatSnackBar,
    private facilityService: FacilityService,
    private utility: Utility,
    private loadingService: LoadingService
  ) {
    this.dataSource = new MatTableDataSource();
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.getUrl();
    this.getFacility();
    this.getSlotConfiguration();
  }

  getUrl() {
    this.currentAccount = this.router.url.includes('affiliate') ? 'affiliate' : 'admin';
  }

  getFacility() {
    this.isLoading = true;
    this.facilityService.getFacilityDetails(this.slotProperty.idFacility).pipe(
      retry(1),
    ).subscribe(res => {
      this.isLoading = false;
      this.facility = res;
    }, error => {
      this.isLoading = false;
    })
  }


  getSlotConfiguration() {
    this.isLoading = true;
    this.facilityService.slotConfigurationGet(this.slotProperty.idFacility).pipe(
      take(1)
    ).subscribe(res => {
      this.isLoading = false;
      this.checkSlotConfigurationsResponse(res);
    }, error => {
      this.isLoading = false;
    });
  }

  checkSlotConfigurationsResponse(response: ISlotConfiguration) {
    if (!response) {
      const durations: Array<IDuration> = [];
      const slotDurationsAfterCheck: Array<IDuration> = [];
      sessionStorage.setItem('durations', JSON.stringify(durations));
      sessionStorage.setItem('slotDurationsAfterCheck', JSON.stringify(slotDurationsAfterCheck));
      this.openFirstSlotCreationDialog();
    } else {
      this.slotConfigurations = {
        configurationOption: response.configurationOption,
        durations: response.durations,
        facilityId: response.facilityId,
      };
      this.fillDurationsListBasedMode();
      sessionStorage.setItem('slotDurationsAfterCheck', JSON.stringify(this.slotConfigurations.durations));
      sessionStorage.setItem('durations', JSON.stringify(this.durations));

      if (!this.durations.length) {
        this.openFirstSlotCreationDialog();
      }
    }
  }

  fillDurationsListBasedMode() {
    this.durations = this.slotConfigurations.durations.filter(duration => duration.service.id === this.slotProperty.idService);
  }

  openFirstSlotCreationDialog() {
    const dialogRef = this.dialog.open(FirstSlotCreationtDialogComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        this.getSlotConfiguration();
      }
      if (result === 'cancel' && !this.durations.length && this.currentAccount === 'affiliate') {
        this.router.navigate(['/affiliate/slotConfiguration']);
      }
      if (result === 'cancel' && !this.durations.length && this.currentAccount === 'admin') {
        this.router.navigate(['/admin/slotConfiguration']);
      }
    });
  }


  openAddDialog() {
    const dialogRef = this.dialog.open(AddManageSlotDialogComponent, {
      width: '500px',
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result !== 'cancel') {
        this.getSlotConfiguration();
      }
      if(result === 'cancel' && !this.durations.length && this.currentAccount === 'affiliate' ) {
        this.router.navigate(['/affiliate/slotConfiguration']);
      }
      if(result === 'cancel' && !this.durations.length && this.currentAccount === 'admin' ) {
        this.router.navigate(['/admin/slotConfiguration']);
      }
    });
  }

  openEditDialog(slotConfiguration: ISlotConfiguration) {
    const dialogRef = this.dialog.open(EditManageSlotDialogComponent, {
      width: '500px',
      data: slotConfiguration,
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getSlotConfiguration(): null;
    });
  }

  openDeleteDialog(duration:IDuration) {
    const dialogRef = this.dialog.open(DeleteManageSlotDialogComponent, {
      width: '500px',
      data: duration,
      disableClose: true,
      autoFocus: false
    });
    dialogRef.afterClosed().subscribe(result => {
      result !== 'cancel' ? this.getSlotConfiguration(): null;
    });
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
