import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../app/guard/auth.guard';
import { ResetPasswordGuard } from './guard/reset-password.guard';

import { LoginComponent } from './components/core/login/login.component';
import { SignUpComponent } from './components/core/sign-up/sign-up.component';
import { ResetPasswordComponent } from './components/core/reset-password/reset-password.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full'
  },
  {
    path: 'welcome',
    component: LoginComponent
  },
  {
    path: 'sign-up',
    component: SignUpComponent
  },
  {
    path: 'reset',
    component: ResetPasswordComponent,
    //canActivate: [ResetPasswordGuard]
  },

  {
    path: 'admin',
    loadChildren: '../app/module/admin/admin.module#AdminModule',
    canActivate: [AuthGuard]
  },
  {
    path: 'affiliate',
    loadChildren: '../app/module/affiliate/affiliate.module#AffiliateModule',
    canActivate: [AuthGuard]
  }
];

@NgModule({

  imports: [
    RouterModule.forRoot(routes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
