import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarSuccessComponent } from '../../../../components/core/snackBar/snack-bar-success/snack-bar-success.component';
import { FacilityService } from '../../../../services/facility/facility.service';
import { IFacility } from '../../../../interface/facility';
import { IOpeningTime } from '../../../../interface/opening-time';
import { IDuration } from '../../../../interface/duration';
import { Observable, Subscription } from 'rxjs';
import { map, retry, take, timeout } from 'rxjs/operators';
import { SnackBarErrorComponent } from '../../../../components/core/snackBar/snack-bar-error/snack-bar-error.component';
import { LoadingService } from '../../../../services/loading/loading.service';

@Component({
  selector: 'app-add-manage-slot-dialog',
  templateUrl: './add-manage-slot-dialog.component.html',
  styleUrls: ['./add-manage-slot-dialog.component.scss']
})
export class AddManageSlotDialogComponent implements OnInit, OnDestroy {
  isLoading: boolean;
  isLoadingSubscription: Subscription;
  localSlotProperty: string = sessionStorage.getItem('slotProperty');
  localSlotDurations: string = sessionStorage.getItem('slotDurationsAfterCheck');
  slotProperty = JSON.parse(this.localSlotProperty);
  slotDurationsAfterCheck: Array<IDuration> = JSON.parse(this.localSlotDurations);
  dailyForm: FormGroup;
  weeklyForm: FormGroup;
  openingTimeList$: Observable<IOpeningTime[]>;
  facility: IFacility;
  dayOfWeek: string[] = ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'];
  dateOpening: string = '1970-01-01 ';

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private matSnackBar: MatSnackBar,
    private dialogRef: MatDialogRef<AddManageSlotDialogComponent>,
    private facilityService: FacilityService,
    private loadingService: LoadingService
  ) {
    this.isLoadingSubscription = this.loadingService.getLoadingStatus().subscribe(status => this.isLoading = status);
  }

  ngOnInit() {
    this.getOpeningTimeFromFacility();
    this.getFacility();
    this.checkDailyForm();
  }

  // Call API OpeningTimeList
  getOpeningTimeFromFacility() {
    this.isLoading = true;
    // TODO: implemented virtual scrolling
    this.openingTimeList$ = this.facilityService.openingTimeList(this.slotProperty.idFacility, 0, 500).pipe(
      retry(1),
      take(1),
      map(res => res.contentList)
    );
  }

  // Call API Facility details
  getFacility() {
    this.isLoading = true;
    this.facilityService.getFacilityDetails(this.slotProperty.idFacility).pipe(
      retry(1)
    ).subscribe(res => {
      this.isLoading = false;
      this.facility = res;
    }, error => {
      this.isLoading = false;
      this.closeDialog();
    });
  }


  // Check Daily Form
  checkDailyForm() {
    this.dailyForm = this.formBuilder.group({
      dayOfWeek: [null, Validators.required],
      durations: this.formBuilder.array([]),
      facilityId: [this.slotProperty.idFacility],
      configurationOption: 0
    });
  }


  // Get durations array form.
  get durationsForm() {
    return this.dailyForm.get('durations') as FormArray;
  }

  // Intercept event to select day of opening time
  onChangeOpeningTime(openingTime: IOpeningTime) {
    const splitFrom = openingTime.from.split(':');
    const splitTo = openingTime.to.split(':');
    const duration = this.formBuilder.group({
      targetDay: [this.dayOfWeek[openingTime.targetDay]],
      from: [`${splitFrom[0]}:${splitFrom[1]}`],
      to: [`${splitTo[0]}:${splitTo[1]}`],
      serviceId: [this.slotProperty.idService],
      openingTimeId: [openingTime.id],
      durationInMinutes: [this.slotProperty.slotDurationInMinutes, [Validators.required]],
      capacity: [1, [Validators.required]]
    });

    if (this.durationsForm.length) {
      this.checkDurationIfExists(duration);
    } else {
      this.durationsForm.push(duration);
    }
  }

  // Method to control if duration item exists
  checkDurationIfExists(duration: any) {
    let find: boolean;
    this.durationsForm.controls.forEach(item => {
      if (item.get('openingTimeId').value == duration.get('openingTimeId').value) {
        find = true;
        return;
      }
    });
    if (!find) {
      this.durationsForm.push(duration);
    }
  }

  // Method to remove duration item from array form durations
  removeDuration(indexDuration: number) {
    this.durationsForm.removeAt(indexDuration);
    if (!this.durationsForm.length) {
      this.dailyForm.reset();
    }
  }


  fillDurationsList(): Array<any> {
    const durations = [];
    if (this.slotDurationsAfterCheck && this.slotDurationsAfterCheck.length) {
      this.slotDurationsAfterCheck.forEach(duration => {
        if (duration.openingTime) {
          durations.push({
            serviceId: duration.service.id,
            openingTimeId: duration.openingTime.id,
            durationInMinutes: duration.durationInMinutes,
            capacity: duration.capacity
          });
        } else {
          durations.push({
            serviceId: duration.service.id,
            openingTimeId: duration.openingTime,
            durationInMinutes: duration.durationInMinutes,
            capacity: duration.capacity
          });
        }
      });
    }
    return durations;
  }


  // Intercepts submit event from daily from
  onSubmitDailyForm() {
    if (this.dailyForm.invalid) {
      return;
    }
    const dailyForm = this.dailyForm.value;
    const durations = this.fillDurationsList();
    this.durationsForm.controls.forEach(duration => {
      durations.push({
        serviceId: duration.get('serviceId').value,
        openingTimeId: duration.get('openingTimeId').value,
        durationInMinutes: Number(duration.get('durationInMinutes').value),
        capacity: Number(duration.get('capacity').value)
      })
    });
    const slotConfUpsert = {
      durations: durations,
      facilityId: dailyForm.facilityId,
      configurationOption: dailyForm.configurationOption
    };
    this.upsertSlotConfiguration(slotConfUpsert);
  }


  // Call API upsert slot configuration
  upsertSlotConfiguration(slotConfUpsert: any) {
    this.isLoading = true;
    this.facilityService.slotConfigurationUpsert(slotConfUpsert).pipe(
      timeout(600000),
    ).subscribe(res => {
      this.isLoading = false;
      this.matSnackBar.openFromComponent(SnackBarSuccessComponent, {
        data: 'Slot configurato con successo',
        duration: 3000,
        verticalPosition: "top",
        horizontalPosition: 'right',
        panelClass: "success"
      });
      this.dialogRef.close();
    }, error => {
      this.isLoading = false;
      this.dialogRef.close('error');
    })
  }

  // Close Dialog
  closeDialog() {
    this.dialogRef.close('cancel');
  }

  ngOnDestroy() {
    this.isLoadingSubscription.unsubscribe();
  }
}
